import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPad/Add_Camera/New_P2P_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-p2p-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-p2p-kamera",
        "aria-label": "hinzufügen einer neuen p2p kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen P2P-Kamera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f0960b242011dbab4cd10ff473f8a37/49853/iPad_Wizard_New_P2P_Cam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoz5WSbY8bIQyE9///zabStQtLbIPfMEuVtNnmNnfVdfR8MhphmFl+pOua0uVyyTmram1a2jAfvd9wj4gdsSLWiP33MGKv1L69leWKxMyqysw9QtQLWm3WHjA7QAVozH4Mha1QX7Yt55znQ9GDUADegaiI+n7ICLwAQK11zrnf1XsgyhdZSilENOccY3xoJtKDD8w55/2hfl+7ktD9GIBTgnUt63pNCQD4mSUi3P0wjzES6lvRBKpq7r01qZVr5dbYPQ5EY5kvwmaFtDbp3efn6jGX/axRqq0gKsLMIvKZ2V/NEXFbkhjxBhG3p8yfqc3O5tffPmX+zPnNEeM/cv7HzbcafcV8NGxEtEfIp2IQ/a3NH7OZiUjOuTXubj/Bvm+eyd37tl1LARE1cwAiamYuoohVxMz8F0GvZE9Gk4p5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f0960b242011dbab4cd10ff473f8a37/e4706/iPad_Wizard_New_P2P_Cam_01.avif 230w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/d1af7/iPad_Wizard_New_P2P_Cam_01.avif 460w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/7f308/iPad_Wizard_New_P2P_Cam_01.avif 920w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/e1c99/iPad_Wizard_New_P2P_Cam_01.avif 1380w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/76ea5/iPad_Wizard_New_P2P_Cam_01.avif 1840w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/b105c/iPad_Wizard_New_P2P_Cam_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f0960b242011dbab4cd10ff473f8a37/a0b58/iPad_Wizard_New_P2P_Cam_01.webp 230w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/bc10c/iPad_Wizard_New_P2P_Cam_01.webp 460w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/966d8/iPad_Wizard_New_P2P_Cam_01.webp 920w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/445df/iPad_Wizard_New_P2P_Cam_01.webp 1380w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/78de1/iPad_Wizard_New_P2P_Cam_01.webp 1840w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/03b06/iPad_Wizard_New_P2P_Cam_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f0960b242011dbab4cd10ff473f8a37/81c8e/iPad_Wizard_New_P2P_Cam_01.png 230w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/08a84/iPad_Wizard_New_P2P_Cam_01.png 460w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/c0255/iPad_Wizard_New_P2P_Cam_01.png 920w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/b1001/iPad_Wizard_New_P2P_Cam_01.png 1380w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/161ec/iPad_Wizard_New_P2P_Cam_01.png 1840w", "/en/static/6f0960b242011dbab4cd10ff473f8a37/49853/iPad_Wizard_New_P2P_Cam_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f0960b242011dbab4cd10ff473f8a37/c0255/iPad_Wizard_New_P2P_Cam_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Aufruf des Menüs `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie die Option `}<strong parentName="li">{`Kamera hinzufügen`}</strong>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9b81c656985402d05c58fc1ded7c93e/49853/iPad_Wizard_New_P2P_Cam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz32SiY4jIQxE+/+/cqXJao8J0GDjA2xYpXvS6WRmtvQkzpIFruXPdX2/Xi+XSwhBRJAkVWd1697vhBBDiMfSbCDUt99pWQsQkYgQUTdjaakooNb6IKWSUjnvMGmCvsQYQgjzLusGhXN+AkAA5HmTSqYl54yIc86xqXcrhQ9225nz6ZJSAoA5p7vv5pxpZ11rjJASxogxwgauKx31b+YQwrjLzJgbcxfpRBrjuj04b5McY65Vtgs3FjNrrR1mHyMU+RUpFhJhlkPMLKoytq9JPN/yXOaLtuJm1rt9qf2W9ll1LuNZ7g5iiVy6DzfzXffRffOPxPNH+mQePgrSWipgrbUi7tx0LN0MZbzjeDX3boiyoXtvEHXnPr91C4Er8uubzTxnPjfzO3LhLyo/jjP93/9hPhLmZhUYTvE6pe0Ruw+zqjJzCKFW6k3/Zv0ZW4DWWt+DsbW35QwAVbUxSynIrKrtH9reZFbsqT23AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b81c656985402d05c58fc1ded7c93e/e4706/iPad_Wizard_New_P2P_Cam_02.avif 230w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/d1af7/iPad_Wizard_New_P2P_Cam_02.avif 460w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/7f308/iPad_Wizard_New_P2P_Cam_02.avif 920w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/e1c99/iPad_Wizard_New_P2P_Cam_02.avif 1380w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/76ea5/iPad_Wizard_New_P2P_Cam_02.avif 1840w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/b105c/iPad_Wizard_New_P2P_Cam_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9b81c656985402d05c58fc1ded7c93e/a0b58/iPad_Wizard_New_P2P_Cam_02.webp 230w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/bc10c/iPad_Wizard_New_P2P_Cam_02.webp 460w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/966d8/iPad_Wizard_New_P2P_Cam_02.webp 920w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/445df/iPad_Wizard_New_P2P_Cam_02.webp 1380w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/78de1/iPad_Wizard_New_P2P_Cam_02.webp 1840w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/03b06/iPad_Wizard_New_P2P_Cam_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b81c656985402d05c58fc1ded7c93e/81c8e/iPad_Wizard_New_P2P_Cam_02.png 230w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/08a84/iPad_Wizard_New_P2P_Cam_02.png 460w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/c0255/iPad_Wizard_New_P2P_Cam_02.png 920w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/b1001/iPad_Wizard_New_P2P_Cam_02.png 1380w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/161ec/iPad_Wizard_New_P2P_Cam_02.png 1840w", "/en/static/a9b81c656985402d05c58fc1ded7c93e/49853/iPad_Wizard_New_P2P_Cam_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9b81c656985402d05c58fc1ded7c93e/c0255/iPad_Wizard_New_P2P_Cam_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie können nun mit dem Hinzufügen des Fernzugriffs über den `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`Point2Point (P2P)`}</a>{` oder `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/DDNS/"
        }}>{`DDNS`}</a>{` Dienst fortfahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/49853/iPad_Wizard_New_P2P_Cam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVQoz8WT227DIBBE+f/fTCo1Tobb2rALC65qFNdqVSXtS87bjJhFiwbzfvXXG87nEwBmpiVbElBhlpxzSklEAFhrRT6dnDMze++naTI+UkqJmVNKtSpLubl4gYsx1g1VHRlVrXdUtbVmrAWA9U7vPcZgLWqt6+/0DRNCmOd5121jJPsjjHOOiI7h4+zHYQC7bq31pzGqWkr5Z/jnS6xPY74N+9vNrwu/eudDK9q69l3t/rE5X/UcfwXAsqRaZAryZgXEpVS3kXMWkRACEY3DMcZhfgC9E3WUjgW9dQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f4a3ad8fcef05f368842b70a2355ac8/e4706/iPad_Wizard_New_P2P_Cam_03.avif 230w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/d1af7/iPad_Wizard_New_P2P_Cam_03.avif 460w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/7f308/iPad_Wizard_New_P2P_Cam_03.avif 920w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/e1c99/iPad_Wizard_New_P2P_Cam_03.avif 1380w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/76ea5/iPad_Wizard_New_P2P_Cam_03.avif 1840w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/b105c/iPad_Wizard_New_P2P_Cam_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4f4a3ad8fcef05f368842b70a2355ac8/a0b58/iPad_Wizard_New_P2P_Cam_03.webp 230w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/bc10c/iPad_Wizard_New_P2P_Cam_03.webp 460w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/966d8/iPad_Wizard_New_P2P_Cam_03.webp 920w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/445df/iPad_Wizard_New_P2P_Cam_03.webp 1380w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/78de1/iPad_Wizard_New_P2P_Cam_03.webp 1840w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/03b06/iPad_Wizard_New_P2P_Cam_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f4a3ad8fcef05f368842b70a2355ac8/81c8e/iPad_Wizard_New_P2P_Cam_03.png 230w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/08a84/iPad_Wizard_New_P2P_Cam_03.png 460w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/c0255/iPad_Wizard_New_P2P_Cam_03.png 920w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/b1001/iPad_Wizard_New_P2P_Cam_03.png 1380w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/161ec/iPad_Wizard_New_P2P_Cam_03.png 1840w", "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/49853/iPad_Wizard_New_P2P_Cam_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4f4a3ad8fcef05f368842b70a2355ac8/c0255/iPad_Wizard_New_P2P_Cam_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Wählen Sie aus, ob es sich bei Ihrer Kamera um ein Schwenk-Neige-Modell oder eine Kamera mit festem Kamerakopf handelt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/330a653ac40220f5b6a9df1012c99a08/49853/iPad_Wizard_New_P2P_Cam_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVQoz6WT226DMAyGef+X3MW6dhRCzgc7sT0BpYNKa1XtUy7iyF9+xVK678EMozqdPpVSpRQf89VkF3LJN2qtWmtjDCKWBQCwxnx8XTvjfEqplJJSqrUVwIzciJmZiBAxhLDKMca6Qa0loG6alFJKNpjIWaOnyTkXYxQRWEBEABDZNTJ31toQwlzxLW0YhsvlYq1NKbXWaEfbWJJbp7X23osIEa3yOI593xtjcs70N4A0y0op3iCivu/P57MxJqX0IOyTM7SutYaIe1lrPY7jOmdmrkvDzFLeqY07OcLM+z0zpxSttc654D0f6R5qIjrcJRJQbBEPEkD4mPRCFpFGv0velZ/w7M2v5X8lr859tu8lA0DOWSkVY6oIVwtfGiZfEOfPpLXOOc/fyFrv/drsnFsPfwBpD3F97kOBtgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/330a653ac40220f5b6a9df1012c99a08/e4706/iPad_Wizard_New_P2P_Cam_04.avif 230w", "/en/static/330a653ac40220f5b6a9df1012c99a08/d1af7/iPad_Wizard_New_P2P_Cam_04.avif 460w", "/en/static/330a653ac40220f5b6a9df1012c99a08/7f308/iPad_Wizard_New_P2P_Cam_04.avif 920w", "/en/static/330a653ac40220f5b6a9df1012c99a08/e1c99/iPad_Wizard_New_P2P_Cam_04.avif 1380w", "/en/static/330a653ac40220f5b6a9df1012c99a08/76ea5/iPad_Wizard_New_P2P_Cam_04.avif 1840w", "/en/static/330a653ac40220f5b6a9df1012c99a08/b105c/iPad_Wizard_New_P2P_Cam_04.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/330a653ac40220f5b6a9df1012c99a08/a0b58/iPad_Wizard_New_P2P_Cam_04.webp 230w", "/en/static/330a653ac40220f5b6a9df1012c99a08/bc10c/iPad_Wizard_New_P2P_Cam_04.webp 460w", "/en/static/330a653ac40220f5b6a9df1012c99a08/966d8/iPad_Wizard_New_P2P_Cam_04.webp 920w", "/en/static/330a653ac40220f5b6a9df1012c99a08/445df/iPad_Wizard_New_P2P_Cam_04.webp 1380w", "/en/static/330a653ac40220f5b6a9df1012c99a08/78de1/iPad_Wizard_New_P2P_Cam_04.webp 1840w", "/en/static/330a653ac40220f5b6a9df1012c99a08/03b06/iPad_Wizard_New_P2P_Cam_04.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/330a653ac40220f5b6a9df1012c99a08/81c8e/iPad_Wizard_New_P2P_Cam_04.png 230w", "/en/static/330a653ac40220f5b6a9df1012c99a08/08a84/iPad_Wizard_New_P2P_Cam_04.png 460w", "/en/static/330a653ac40220f5b6a9df1012c99a08/c0255/iPad_Wizard_New_P2P_Cam_04.png 920w", "/en/static/330a653ac40220f5b6a9df1012c99a08/b1001/iPad_Wizard_New_P2P_Cam_04.png 1380w", "/en/static/330a653ac40220f5b6a9df1012c99a08/161ec/iPad_Wizard_New_P2P_Cam_04.png 1840w", "/en/static/330a653ac40220f5b6a9df1012c99a08/49853/iPad_Wizard_New_P2P_Cam_04.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/330a653ac40220f5b6a9df1012c99a08/c0255/iPad_Wizard_New_P2P_Cam_04.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d8cebc41200ca966f3afa0760268279/49853/iPad_Wizard_New_P2P_Cam_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAz0lEQVQoz62SwYrDMAxEI7mRI1nGiWISkksg//+RCzZbukt23Za+gw6SB43MdN2NgLh7E3Tgbt1nAQBErPWp18w8fGNmOed5nnPOInLvO+eu7COGEBDRFe5ra60dIhqG4Vp8PXjAOee9b4sRsS88+myLubAsy3Ec53mu6yqFp8QxRjNLKYlIjFFVp2kysxdsv3wzMzfF1wsAQFWZWVVFhH9SRyLS9/1/qdr3nYh+mdq2zXsPAI2o1Rgxc0ppHEdVrae2lRUiCiFoIYTw1198AfBBCxfs+4/oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/e4706/iPad_Wizard_New_P2P_Cam_05.avif 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/d1af7/iPad_Wizard_New_P2P_Cam_05.avif 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/7f308/iPad_Wizard_New_P2P_Cam_05.avif 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/e1c99/iPad_Wizard_New_P2P_Cam_05.avif 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/76ea5/iPad_Wizard_New_P2P_Cam_05.avif 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/b105c/iPad_Wizard_New_P2P_Cam_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/a0b58/iPad_Wizard_New_P2P_Cam_05.webp 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/bc10c/iPad_Wizard_New_P2P_Cam_05.webp 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/966d8/iPad_Wizard_New_P2P_Cam_05.webp 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/445df/iPad_Wizard_New_P2P_Cam_05.webp 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/78de1/iPad_Wizard_New_P2P_Cam_05.webp 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/03b06/iPad_Wizard_New_P2P_Cam_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d8cebc41200ca966f3afa0760268279/81c8e/iPad_Wizard_New_P2P_Cam_05.png 230w", "/en/static/0d8cebc41200ca966f3afa0760268279/08a84/iPad_Wizard_New_P2P_Cam_05.png 460w", "/en/static/0d8cebc41200ca966f3afa0760268279/c0255/iPad_Wizard_New_P2P_Cam_05.png 920w", "/en/static/0d8cebc41200ca966f3afa0760268279/b1001/iPad_Wizard_New_P2P_Cam_05.png 1380w", "/en/static/0d8cebc41200ca966f3afa0760268279/161ec/iPad_Wizard_New_P2P_Cam_05.png 1840w", "/en/static/0d8cebc41200ca966f3afa0760268279/49853/iPad_Wizard_New_P2P_Cam_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d8cebc41200ca966f3afa0760268279/c0255/iPad_Wizard_New_P2P_Cam_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder der Kameraverpackung zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a44d5114337938050f3e5399c367bd29/49853/iPad_Wizard_New_P2P_Cam_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWElEQVQoz62S20oDQQyG95X1Sij0YQSxPkBBqtd6J7QvIGoL0jo7p+1MMslMZHfteqpFwY9chJ/8ySRM9bwxmxe1XD4ppQDAb+PGgmsixJYQAiJqrY0xiBhCiDECgDH6frWujPMhBAAIIRAxYGoiYSIi4pxLKUTknLPWMnMpJeeciDLzFnNV10opJTtKKUMeQnAddYe11jkHAENBZa1tmqa39Y211r1CRIhIO1JKiMjM72attfdeRHL3SGY2xqSU5CB9cWtWSpUdOWdjTN+Os+SyJ4bNKmZOKX00AwARHZ7cH6/6opZSEpFkunmQ0zuZzOV8LhcLmSzaZDJvxdvHtxNV5TPdMm2Xs7kcTWU0k5NLGV/L+KpNRjM5nrZdOrfsMcuv+dHMRSjvDx4O9n3nf5j8B/Pww/42GRFjjEqp7TZQwrXFlcbaQ0qkO2KMiGit9d73xc65XnwFLXJoK8hRdE0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a44d5114337938050f3e5399c367bd29/e4706/iPad_Wizard_New_P2P_Cam_06.avif 230w", "/en/static/a44d5114337938050f3e5399c367bd29/d1af7/iPad_Wizard_New_P2P_Cam_06.avif 460w", "/en/static/a44d5114337938050f3e5399c367bd29/7f308/iPad_Wizard_New_P2P_Cam_06.avif 920w", "/en/static/a44d5114337938050f3e5399c367bd29/e1c99/iPad_Wizard_New_P2P_Cam_06.avif 1380w", "/en/static/a44d5114337938050f3e5399c367bd29/76ea5/iPad_Wizard_New_P2P_Cam_06.avif 1840w", "/en/static/a44d5114337938050f3e5399c367bd29/b105c/iPad_Wizard_New_P2P_Cam_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a44d5114337938050f3e5399c367bd29/a0b58/iPad_Wizard_New_P2P_Cam_06.webp 230w", "/en/static/a44d5114337938050f3e5399c367bd29/bc10c/iPad_Wizard_New_P2P_Cam_06.webp 460w", "/en/static/a44d5114337938050f3e5399c367bd29/966d8/iPad_Wizard_New_P2P_Cam_06.webp 920w", "/en/static/a44d5114337938050f3e5399c367bd29/445df/iPad_Wizard_New_P2P_Cam_06.webp 1380w", "/en/static/a44d5114337938050f3e5399c367bd29/78de1/iPad_Wizard_New_P2P_Cam_06.webp 1840w", "/en/static/a44d5114337938050f3e5399c367bd29/03b06/iPad_Wizard_New_P2P_Cam_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a44d5114337938050f3e5399c367bd29/81c8e/iPad_Wizard_New_P2P_Cam_06.png 230w", "/en/static/a44d5114337938050f3e5399c367bd29/08a84/iPad_Wizard_New_P2P_Cam_06.png 460w", "/en/static/a44d5114337938050f3e5399c367bd29/c0255/iPad_Wizard_New_P2P_Cam_06.png 920w", "/en/static/a44d5114337938050f3e5399c367bd29/b1001/iPad_Wizard_New_P2P_Cam_06.png 1380w", "/en/static/a44d5114337938050f3e5399c367bd29/161ec/iPad_Wizard_New_P2P_Cam_06.png 1840w", "/en/static/a44d5114337938050f3e5399c367bd29/49853/iPad_Wizard_New_P2P_Cam_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a44d5114337938050f3e5399c367bd29/c0255/iPad_Wizard_New_P2P_Cam_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a02b44d868c71ce4072bae1e37342272/49853/iPad_Wizard_New_P2P_Cam_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WTzUoDMRCA9+X1IqVeqi/iRTwo2EL7BiKCB1uQ7qbZ2XR3fvIzkt22IFa0iB8hJJN8ySQkxXtZl5VZLt+MMUTkWiwBXYuEO5jZWlvXtYgMESKqa/u6Whd147quI6Ku67wPxLJFER9STwgBEV0PEcUYU0q5jrHlWGw2xhije1KMqsrMgwAAVVVt9gwRABARVS0AYLvdZq0nxggAZVk2OXXfISN7ZE+SayRpkZE4hJBla61zTlUPKVlrTVV5xiEVPUZKaScbY9Kefok8fPOkZ/c6murFo17OdNw3RlM9f8hDva9FCEFEvsq3zzqe6dVCJ3O9XuQymefueKZ3L3v5aD6/pEifif1t5ytIGr4pMf0k/2nnX8n/cuYT5MMLO21nZkZEY0zbdl54DbyyvHEk4m3P8LEAwDk3TG6aZgh+AM2VZ6VLyF7fAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02b44d868c71ce4072bae1e37342272/e4706/iPad_Wizard_New_P2P_Cam_07.avif 230w", "/en/static/a02b44d868c71ce4072bae1e37342272/d1af7/iPad_Wizard_New_P2P_Cam_07.avif 460w", "/en/static/a02b44d868c71ce4072bae1e37342272/7f308/iPad_Wizard_New_P2P_Cam_07.avif 920w", "/en/static/a02b44d868c71ce4072bae1e37342272/e1c99/iPad_Wizard_New_P2P_Cam_07.avif 1380w", "/en/static/a02b44d868c71ce4072bae1e37342272/76ea5/iPad_Wizard_New_P2P_Cam_07.avif 1840w", "/en/static/a02b44d868c71ce4072bae1e37342272/b105c/iPad_Wizard_New_P2P_Cam_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a02b44d868c71ce4072bae1e37342272/a0b58/iPad_Wizard_New_P2P_Cam_07.webp 230w", "/en/static/a02b44d868c71ce4072bae1e37342272/bc10c/iPad_Wizard_New_P2P_Cam_07.webp 460w", "/en/static/a02b44d868c71ce4072bae1e37342272/966d8/iPad_Wizard_New_P2P_Cam_07.webp 920w", "/en/static/a02b44d868c71ce4072bae1e37342272/445df/iPad_Wizard_New_P2P_Cam_07.webp 1380w", "/en/static/a02b44d868c71ce4072bae1e37342272/78de1/iPad_Wizard_New_P2P_Cam_07.webp 1840w", "/en/static/a02b44d868c71ce4072bae1e37342272/03b06/iPad_Wizard_New_P2P_Cam_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02b44d868c71ce4072bae1e37342272/81c8e/iPad_Wizard_New_P2P_Cam_07.png 230w", "/en/static/a02b44d868c71ce4072bae1e37342272/08a84/iPad_Wizard_New_P2P_Cam_07.png 460w", "/en/static/a02b44d868c71ce4072bae1e37342272/c0255/iPad_Wizard_New_P2P_Cam_07.png 920w", "/en/static/a02b44d868c71ce4072bae1e37342272/b1001/iPad_Wizard_New_P2P_Cam_07.png 1380w", "/en/static/a02b44d868c71ce4072bae1e37342272/161ec/iPad_Wizard_New_P2P_Cam_07.png 1840w", "/en/static/a02b44d868c71ce4072bae1e37342272/49853/iPad_Wizard_New_P2P_Cam_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a02b44d868c71ce4072bae1e37342272/c0255/iPad_Wizard_New_P2P_Cam_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      